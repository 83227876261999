import { joinURL } from "ufo";
import getURL from "requrl";
import { sanitizeStatusCode } from "h3";
import { abortNavigation, useAuthState, useNuxtApp, useRequestEvent } from "#imports";
export const getRequestURL = (includePath = true) => getURL(useRequestEvent()?.node.req, includePath);
export function joinPathToApiURL(path) {
  const authStateInternal = useAuthState()._internal;
  const base = path.startsWith("/") ? authStateInternal.pathname : authStateInternal.baseURL;
  return joinURL(base, path);
}
export function navigateToAuthPages(href) {
  const nuxtApp = useNuxtApp();
  if (import.meta.server) {
    if (nuxtApp.ssrContext) {
      return nuxtApp.callHook("app:redirected").then(() => {
        const encodedLoc = href.replace(/"/g, "%22");
        const encodedHeader = new URL(href).toString();
        nuxtApp.ssrContext._renderResponse = {
          statusCode: sanitizeStatusCode(302, 302),
          body: `<!DOCTYPE html><html><head><meta http-equiv="refresh" content="0; url=${encodedLoc}"></head></html>`,
          headers: { location: encodedHeader }
        };
        abortNavigation();
      });
    }
  }
  window.location.href = href;
  if (href.includes("#")) {
    window.location.reload();
  }
  const router = nuxtApp.$router;
  const waitForNavigationWithFallbackToRouter = new Promise((resolve) => setTimeout(resolve, 60 * 1e3)).then(() => router.push(href));
  return waitForNavigationWithFallbackToRouter;
}
export function determineCallbackUrl(authConfig, getOriginalTargetPath) {
  const authConfigCallbackUrl = typeof authConfig.globalAppMiddleware === "object" ? authConfig.globalAppMiddleware.addDefaultCallbackUrl : void 0;
  if (typeof authConfigCallbackUrl !== "undefined") {
    if (typeof authConfigCallbackUrl === "string") {
      return authConfigCallbackUrl;
    }
    if (typeof authConfigCallbackUrl === "boolean") {
      if (authConfigCallbackUrl) {
        return getOriginalTargetPath();
      }
    }
  } else if (authConfig.globalAppMiddleware === true) {
    return getOriginalTargetPath();
  }
}
